<template>
	<section id="about" class="py-10">
		<v-container fluid>
			<p class="text-h4 text-center font-weight-bold text-uppercase mb-2">Nuestros pilares principales</p>
			<p class="text-h6 text-center mx-auto" style="max-width: 1000px">
				Queremos llegar muy lejos junto a ti y ayudarte con todo lo que tenga que ver con tu educación.
			</p>
			<p class="text-subtitle-1 text-center mx-auto" style="max-width: 800px">
				No solo estamos enfocados al estudiante, sino que OverSOS es una plataforma dirigida también a alumni con el objetivo de hacer networking tras
				finalizar los estudios.
			</p>
			<v-card max-width="1400" class="mt-15 mx-auto d-flex flex-wrap justify-space-around" flat tile color="transparent">
				<v-card color="transparent" class="mt-3 text-center" max-width="300" flat tile>
					<v-icon size="48"> mdi-school </v-icon>
					<v-card-title>
						<span class="mx-auto">SOS</span>
					</v-card-title>
					<v-card-subtitle>
						<span class="mx-auto">Comunidad cooperativa</span>
					</v-card-subtitle>
					<v-card-text class="text-justify">
						<p>Sube y descarga apuntes, encuentra información de tus asignaturas, resuelve tus dudas.</p>
					</v-card-text>
				</v-card>
				<v-card color="transparent" class="mt-3 text-center" max-width="300" flat tile>
					<v-icon size="48"> mdi-account-group </v-icon>
					<v-card-title>
						<span class="mx-auto">OverSOS Talent Pool</span>
					</v-card-title>
					<v-card-subtitle>
						<span class="mx-auto">Haz que te busquen</span>
					</v-card-subtitle>
					<v-card-text class="text-justify">
						<p>
							A veces sienta bien sentirse querido. Ahora puedes formar parte de nuestro Talent Pool para que sean las empresas quien te busquen a tí.
						</p>
					</v-card-text>
				</v-card>
				<v-card color="transparent" class="mt-3 text-center" max-width="300" flat tile>
					<v-icon size="48"> mdi-briefcase </v-icon>
					<v-card-title>
						<span class="mx-auto">OverSOS Jobs</span>
					</v-card-title>
					<v-card-subtitle>
						<span class="mx-auto">Conectar empresas con estudiantes</span>
					</v-card-subtitle>
					<v-card-text class="text-justify">
						<p>Encuentra prácticas o trabajo mientras terminas tu carrera o encuentra una empresa en la que hacer tu TFG/TFM.</p>
					</v-card-text>
				</v-card>
				<v-card color="transparent" class="mt-3 text-center" max-width="300" flat tile>
					<v-icon size="48"> mdi-web </v-icon>
					<v-card-title>
						<span class="mx-auto">OverSOS Network</span>
					</v-card-title>
					<v-card-subtitle>
						<span class="mx-auto">Networking con empresas</span>
					</v-card-subtitle>
					<v-card-text class="text-justify">
						<p>En OverSOS se comparten eventos a los que poder asistir con tal de relacionarse e interactuar con empresas interesantes.</p>
					</v-card-text>
				</v-card>
			</v-card>
		</v-container>
	</section>
</template>

<script>
export default {
	name: 'LandingAbout',
}
</script>
